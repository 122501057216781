import React, { Component } from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Icon } from 'react-fa';

import './App.css';
import axios from 'axios';
import ActivityList from './ActivityList'

const fabStyle = {
    marginRight: 20,
    marginBottom: 20,
    position: 'fixed',
    bottom: 0,
    right: 0,
};

class Activity extends Component {

    constructor(props) {
        super(props);

        this.handlePayClick = this.handlePayClick.bind(this);
        this.fetchActivity = this.fetchActivity.bind(this);

        this.state = {
            transactions: [],
            isFetching: false,

            snackOpen: false,
            snackMessage: ''
        };
    }

    componentDidMount() {
        this.fetchActivity()
        this.setState({
            isFetching: true
        });
    }

    fetchActivity(successMessage) {
        console.log("Fetching activity..");

        window.refreshUser()
        axios.get('/payments')
            .then(response => {
                console.log("Response for tx history: ", response);
                const transactions = response.data;

                let snackOpen = false;
                if (successMessage) {
                    snackOpen = successMessage.length > 0;
                }

                this.setState({
                    transactions,
                    isFetching: false,
                    snackOpen,
                    snackMessage: successMessage || ""
                });
            })
            .catch(error => {
                console.error("Error in GET payments: ", error);
                console.error("Error message: ", error.response);
            });
    }

    handleSnackClose = () => {
        this.setState({
            snackOpen: false,
            snackMessage: ''
        });
    };

    handlePayClick() {
        this.props.history.push('/pay');
    }

    handleCancel = (tx) => {
        this.setState({
            isFetching: true
        });

        axios.put(`/requests/${tx.id}`, {
            action: "cancel"
        })
            .then(response => {
                this.fetchActivity("You successfully cancelled this request");
            })
            .catch(error => {
                this.setState({
                    snackOpen: false,
                    snackMessage: `Failed to cancel this request: ${error.message}`
                });
            });
    }

    handleApprove = (tx) => {
        this.setState({
            isFetching: true
        });

        axios.put(`/requests/${tx.id}`, {
            action: "approve"
        })
            .then(response => {
                this.fetchActivity("You approved this transaction!");
            })
            .catch(error => {
                console.log("Ahhhhh, error: ", error.response)
                this.setState({
                    isFetching: false,
                    snackOpen: true,
                    snackMessage: `Failed to approve this request: ${error.response.data.error.message}`
                });
            });
    }

    handleDecline = (tx) => {
        this.setState({
            isFetching: true
        });

        axios.put(`/requests/${tx.id}`, {
            action: "decline"
        })
            .then(response => {
                this.fetchActivity("You successfully declined this request!");
            })
            .catch(error => {
                this.setState({
                    snackOpen: false,
                    snackMessage: `Failed to decline this request: ${error.message}`
                });
            });
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color="black" />
            </div>
        );
        const { isFetching, transactions } = this.state

        return (
            <div>
                <div className="container">
                    {isFetching && transactions.length === 0 ? <LoadingIndicator /> : <ActivityList items={transactions} isFetching={isFetching} handleCancel={this.handleCancel} handleDecline={this.handleDecline} handleApprove={this.handleApprove} />}
                </div>

                <Snackbar
                    open={this.state.snackOpen}
                    message={this.state.snackMessage}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackClose}>
                </Snackbar>

                {/* <FloatingActionButton
                    style={fabStyle}
                    backgroundColor="black"
                    onClick={this.handlePayClick}>
                    <Icon name="paper-plane" size="lg" />
                </FloatingActionButton> */}


                <Dimmer.Dimmable dimmed={this.state.isFetching}>
                    <Dimmer active={this.state.isFetching} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default Activity;