import Divider from 'material-ui/Divider'
import Drawer from 'material-ui/Drawer'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import React, { Component } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Base from './Base'
import CarrierControls from './CarrierControls'
import Checkouts from './Checkouts'
import DepositPage from './DepositPage'
import Deposits from './Deposits'
import MobileMoneyProfile from './MobileMoneyProfile'
// Nested Components
import Overview from './Overview'
import PaymentInvitations from './PaymentInvitations'
import Payments from './Payments'
import PendingKYC from './PendingKYC'
import PendingKYCDetails from './PendingKYCDetails'
import PendingWithdrawals from './PendingWithdrawals'
import Referrals from './Referrals'
import Requests from './Requests'
import ScriptSheet from './ScriptSheet'
import SendMoney from './SendMoney'
import UserDevices from './UserDevices'
import UserProfile from './UserProfile'
import Users from './Users'
import WhitelistedStocks from './WhitelistedStocks'
import WithdrawalPage from './WithdrawalPage'
import Withdrawals from './Withdrawals'


class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    toggleAdminPanel = () => {
        this.setState({
            open: !this.state.open
        })
    }

    handleRequestChange = (open) => {
        this.toggleAdminPanel()
    }

    render() {
        return (
            <div>

                <div style={{ textAlign: 'center', marginTop: 30 }}>
                    <h1> Chipper Admin Dashboard </h1>
                    <RaisedButton
                        label="Show More"
                        onTouchTap={this.toggleAdminPanel}
                    />
                    <br />
                    <br />
                </div>

                <Switch>
                    <Route exact path="/admin" component={Overview} />
                    <Route path="/admin/overview" component={Overview} />
                    <Route path="/admin/payments" component={Payments} />
                    <Route path="/admin/requests" component={Requests} />
                    <Route path="/admin/users" component={Users} />
                    <Route path="/admin/user/:id" component={UserProfile} />
                    <Route path="/admin/send" component={SendMoney} />
                    <Route path="/admin/base" component={Base} />
                    <Route path="/admin/withdrawals" component={Withdrawals} />
                    <Route path="/admin/withdrawal/:id" component={WithdrawalPage} />
                    <Route path="/admin/deposits" component={Deposits} />
                    <Route path="/admin/deposit/:id" component={DepositPage} />
                    <Route path="/admin/paymentinvitations" component={PaymentInvitations} />
                    <Route path="/admin/pendingwithdrawals" component={PendingWithdrawals} />
                    <Route path="/admin/carriercontrols" component={CarrierControls} />
                    <Route path="/admin/referrals" component={Referrals} />
                    <Route path="/admin/userdevices" component={UserDevices} />
                    <Route path="/admin/mobilemoney/:momophone" component={MobileMoneyProfile} />
                    <Route path="/admin/checkouts" component={Checkouts} />
                    <Route path="/admin/pendingkyc" component={PendingKYC} />
                    <Route path="/admin/pendingkycdetails/:id" component={PendingKYCDetails} />
                    <Route path="/admin/scriptSheet" component={ScriptSheet} />
                    <Route path="/admin/whitelistedstocks" component={WhitelistedStocks} />
                </Switch>

                <Drawer
                    width={200}
                    openSecondary={true}
                    open={this.state.open}
                    docked={false}
                    onRequestChange={this.handleRequestChange}
                    style={{ textAlign: 'center' }}>

                    <h3 style={{ marginTop: 15 }}>Admin Panel</h3>
                    <Divider />

                    <div style={{ textAlign: 'left' }}>
                        <MenuItem
                            primaryText="Overview"
                            containerElement={<Link to="/admin/overview" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Users"
                            containerElement={<Link to="/admin/users" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Payments"
                            containerElement={<Link to="/admin/payments" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Requests"
                            containerElement={<Link to="/admin/requests" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Withdrawals"
                            containerElement={<Link to="/admin/withdrawals" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Deposits"
                            containerElement={<Link to="/admin/deposits" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Payment Invitations"
                            containerElement={<Link to="/admin/paymentinvitations" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Checkout Transactions"
                            containerElement={<Link to="/admin/checkouts" />}
                            onClick={this.toggleAdminPanel} />

                        <Divider />

                        <MenuItem
                            primaryText="Send Money"
                            containerElement={<Link to="/admin/send" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Manage Base Accounts"
                            containerElement={<Link to="/admin/base" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Pending Withdrawals"
                            containerElement={<Link to="/admin/pendingwithdrawals" />}
                            onClick={this.toggleAdminPanel} />

                        <Divider />

                        <MenuItem
                            primaryText="Carrier Controls"
                            containerElement={<Link to="/admin/carriercontrols" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="Referrals"
                            containerElement={<Link to="/admin/referrals" />}
                            onClick={this.toggleAdminPanel} />

                        <MenuItem
                            primaryText="User Devices"
                            containerElement={<Link to="/admin/userdevices" />}
                            onClick={this.toggleAdminPanel} />

                        <Divider />

                        <MenuItem
                            primaryText="KYC Document Review"
                            containerElement={<Link to="/admin/pendingkyc" />}
                            onClick={this.toggleAdminPanel} />

                        <Divider />

                        <MenuItem
                            primaryText="Whitelisted Stocks"
                            containerElement={<Link to="/admin/whitelistedstocks" />}
                            onClick={this.toggleAdminPanel} />

                    </div>
                </Drawer>
            </div>
        );
    }
}

export default Admin;
