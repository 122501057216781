import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Link, withRouter } from "react-router-dom";
import AppBar from "material-ui/AppBar";
import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import Avatar from "material-ui/Avatar";
import Divider from "material-ui/Divider";
import _ from "lodash";
import api, { loadSavedToken } from "./util/api";
import formattedCurrency from "./util/currencyFormatter";

import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";

import { Icon } from "react-fa";

import { grey50 } from "material-ui/styles/colors";
import NotificationSystem from "react-notification-system";

import axios from "axios";
const firebase = window.firebase;

let unsubscribe;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loggedIn: false,
      isAdmin: false,

      balance: null,
      currency: ""
    };

    // Binding things
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.handleRequestChange = this.handleRequestChange.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);

    window.refreshUser = this.refreshUser;
  }

  async componentDidMount() {
    window.notifications = this.refs.notifications;
    await loadSavedToken();
    this.refreshUser();

    const token = localStorage.getItem("accessToken");

    // If the token is null, route back to the /login screen
    if (token == null) {
      this.handleSignOut();
      return;
    }
  }

  toggleSideBar() {
    this.setState({ open: !this.state.open });
  }

  // Refactor in a lib!!
  /*
    getWalletBalance(wallet, currency) {
        // The balance has { currency: 'GHS', amount: 10 }
        if (!wallet) {
            return {}
        }

        if (!wallet.balances) {
            return {}
        }

        let _balance = {};

        wallet.balances.forEach(balance => {
            if (balance.currency === currency) {
                _balance = balance;
            }
        });

        if (_.isEmpty(_balance)) {
            console.error(`No wallet balance for currency: ${currency} found`);
        }
        return _balance;
    }
    */

  handleRequestChange(open) {
    this.setState({ open: open });
  }

  handleSignOut() {
    localStorage.removeItem("accessToken");

    if (unsubscribe) {
      unsubscribe();
    }

    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({
          loggedIn: false
        });
        this.props.history.push("/login");
        console.log(
          "Access token after sign out: ",
          localStorage.getItem("accessToken")
        );
      })
      .catch(error => {
        console.error("Error occurred during sign out: ", error);
      });
  }

  refreshUser = async () => {
    const token = localStorage.getItem("accessToken");
    axios.defaults.headers["Authorization"] = token;
    const response = await api.authRoutes.account.isAdmin();
    const isAdmin = response.data;
    // Should be based off the presence of a token me thinks.
    const loggedIn = token !== null;
    this.setState({
      loggedIn,
      isAdmin
    });

    axios
      .get("/users/me")
      .then(response => {
        if (response && response.data) {
          console.log("Me: ", response);
          const me = response.data;

          this.setState({
            displayName: `${me.first_name} ${me.last_name}`,
            avatar: me.avatar,
            currency: me.primary_currency,
            isAdmin: isAdmin || false
          });
          console.log("is Admin: ", isAdmin.data);
          // Also update the wallet balance.
          return axios.get("/users/balance", {
            params: {
              currency: this.state.currency
            }
          });
        }
      })
      .then(response => {
        console.log("Response getting params: ", response.data);
        const { currency, balance } = response.data;
        console.log("currency: ", currency);
        console.log("balance: ", balance);
        this.setState({
          currency: currency,
          balance,
          balance
        });

        // Such a hack. Should use redux and stuff
        // Good for now. Just need something to ship and show people
        localStorage.setItem("currency", currency);

        // Setting window.currency?
        console.log("Window.currency: ", window.currency);
      })
      .catch(error => {
        console.dir(error);
        if (error.response && error.response.status === 404) {
          console.log(
            "User reached activity screen without being fully configured"
          );
          this.handleSignOut();
        }
      });
  };

  formattedCurrency = () => {
    // If this.state.balance is undefinined..
    // Say something like Fetching wallet...
    if (this.state.balance === null) {
      return "Fetching Wallet...";
    } else {
      return formattedCurrency(this.state.balance, this.state.currency);
    }
  };

  render() {
    const avatarStyle = { marginTop: 20, objectFit: "cover" };

    const Logged = props => (
      <IconMenu
        {...props}
        iconButtonElement={
          <IconButton>
            <MoreVertIcon color={grey50} />
          </IconButton>
        }
        targetOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          rightIcon={
            <Icon name="sign-out" size="lg" style={{ paddingTop: 5 }} />
          }
          primaryText="Sign out"
          onTouchTap={this.handleSignOut}
        />
      </IconMenu>
    );

    const AdminMenuItem = () => {
      if (this.state.isAdmin) {
        return (
          <MenuItem
            primaryText="Admin"
            rightIcon={<Icon name="eye" size="lg" style={{ paddingTop: 5 }} />}
            containerElement={<Link to="/admin" />}
            onClick={this.toggleSideBar}
          ></MenuItem>
        );
      } else {
        return <div></div>;
      }
    };

    const LoggedInDrawer = props => {
      return (
        <div>
          <div style={{ backgroundColor: "black" }}>
            <Avatar src={this.state.avatar} size={80} style={avatarStyle} />
            <p style={{ color: grey50, marginTop: 15 }}>
              {this.state.displayName}
            </p>
            <p style={{ color: grey50, fontSize: "1.1em" }}>
              {this.formattedCurrency()}
            </p>
            <Divider />
          </div>

          <div style={{ textAlign: "left" }}>
            <MenuItem
              rightIcon={
                <Icon name="list-alt" size="lg" style={{ paddingTop: 5 }} />
              }
              primaryText="Activity"
              containerElement={<Link to="/activity" />}
              onClick={this.toggleSideBar}
            ></MenuItem>

            {/* <MenuItem
                            rightIcon={<Icon name="user-circle" size="lg" style={{ paddingTop: 5 }} />}
                            primaryText="Edit Profile"
                            containerElement={<Link to="/profile" />}
                            onClick={this.toggleSideBar}
                        >
                        </MenuItem> */}

            {/* <MenuItem
                        rightIcon={<Icon name="money" size="lg" style={{paddingTop: 5}} />}
                        primaryText="Cash In/Out"
                        containerElement={<Link to="/cashout" />}
                        onClick={this.toggleSideBar}
                    >
                    </MenuItem> */}

            <AdminMenuItem />
          </div>
        </div>
      );
    };

    const LoggedOutDrawer = props => {
      return (
        <div className="posCenter">
          Sign in or create an account to continue
        </div>
      );
    };

    const DrawerContent = () => {
      if (this.state.loggedIn) {
        return <LoggedInDrawer />;
      } else {
        return <LoggedOutDrawer />;
      }
    };

    const notificationsStyle = {
      Containers: {
        DefaultStyle: {
          padding: "70px"
        }
      }
    };

    return (
      <div>
        <AppBar
          title="Chipper"
          showMenuIconButton={this.state.loggedIn}
          onLeftIconButtonTouchTap={this.toggleSideBar}
          iconElementRight={<Logged />}
          style={{ position: "fixed", backgroundColor: "black" }}
        />

        <div style={{ paddingTop: 62, paddingBottom: 50 }}>
          {React.cloneElement(this.props.children, {
            refreshUser: "this.refreshUser",
            magic: "the heck"
          })}
        </div>

        <Drawer
          open={this.state.open}
          docked={false}
          width={200}
          onRequestChange={this.handleRequestChange}
          style={{ textAlign: "center" }}
        >
          <DrawerContent />
        </Drawer>
        <NotificationSystem ref="notifications" />
      </div>
    );
  }
}

export default withRouter(App);

/*
// Pass in a function to props.children. Leaving commented easy reference
{React.cloneElement(this.props.children, {
    refreshUser: this.refreshUser}
)}
*/

/*
Bring Cash out back when ready

                    */
